import {
  reactive,
  watch,
  watchEffect,
  toRef,
  type UnwrapNestedRefs,
  ref,
  shallowRef,
  unref,
  type MaybeRef
} from 'vue'
import type { IApiPagination, IPaginationPayload } from '../models/Api'
import debounce from '../utils/debounce'

// TODO: завернуть передаваемые параметры в объект
export default <T extends object, P extends IPaginationPayload | object>(
  fetcher: MaybeRef<(params: UnwrapNestedRefs<P>) => Promise<T>>,
  disablePagination = false,
  clearBefore = false,
  defaultPerPage = 10
) => {
  let isPageWatchFetch = true

  const dataRef = shallowRef<T>()
  const autoFetchFilterChange = ref(true)
  const temporaryDisableAutoFetch = ref(false)

  const pagination = reactive<IApiPagination>({
    page: 1,
    per_page: defaultPerPage,
    count: 0,
    page_count: 0
  })

  const loading = ref(false)

  const filters = reactive<P>({} as P)
  const catchData = shallowRef<unknown>()

  watchEffect(() => {
    pagination.page_count = Math.ceil(pagination.count / pagination.per_page)
  })

  watch(
    [toRef(pagination, 'page'), toRef(pagination, 'per_page')],
    async () => {
      if (
        disablePagination ||
        !isPageWatchFetch ||
        temporaryDisableAutoFetch.value
      ) {
        return
      }

      await fetch()
    }
  )

  watch(
    filters,
    debounce(async () => {
      if (!autoFetchFilterChange.value || temporaryDisableAutoFetch.value) {
        return
      }

      isPageWatchFetch = false

      pagination.page = 1

      await fetch()
    }, 300),
    { deep: true }
  )

  async function fetch(params?: UnwrapNestedRefs<P>) {
    if (loading.value) {
      return
    }

    let data: Awaited<T> | undefined

    loading.value = true
    catchData.value = undefined

    if (clearBefore) {
      dataRef.value = undefined
    }

    if (params && 'page' in params && params.page !== undefined) {
      isPageWatchFetch = false
      pagination.page = params.page
    }

    if (params && 'per_page' in params && params.per_page !== undefined) {
      isPageWatchFetch = false
      pagination.per_page = params.per_page
    }

    const paramsFetch = {
      page: disablePagination ? undefined : pagination.page,
      per_page: disablePagination ? undefined : pagination.per_page,
      ...filters,
      ...(params || {})
    }

    try {
      data = await unref(fetcher)(paramsFetch)

      dataRef.value = data

      if ('page' in data) {
        pagination.page = (data.page as number) || 1
      } else {
        pagination.page = 1
      }

      if ('count' in data) {
        pagination.count = (data.count as number) || 0
      } else {
        pagination.count = 0
      }

      if ('per_page' in data) {
        pagination.per_page = (data.per_page as number) || 10
      } else {
        pagination.per_page = 10
      }
    } catch (_e) {
      catchData.value = _e
      dataRef.value = undefined
    } finally {
      temporaryDisableAutoFetch.value = false
      isPageWatchFetch = true
      loading.value = false
    }

    return data
  }

  return {
    dataRef, // shallowRef data из Axios
    pagination,
    filters,
    fetch,
    loading,
    autoFetchFilterChange, //  false - запрещает fetch, при изменении фильтра (fetch нужно вызывать вручную)
    temporaryDisableAutoFetch, // true - !!Временно!! запрещает fetch при изменении фильтра или pagination. (последующий вызов fetch снимет этот запрет)
    catchData
  }
}
