import 'dayjs/locale/ru.js'
import 'dayjs/locale/en.js'
import 'dayjs/locale/ky.js'

import { onBeforeMount, watch, computed, ref } from 'vue'
import { useAuthStore } from '~/common/src/stores/auth'
import { useServicesVisibleStore } from '~/common/src/stores/servicesVisible'
import useUISettingsStore from '~/common/src/stores/uiSettings'
import { useHead } from '@unhead/vue'
import { useI18n } from '~common/composables/useI18n'
import jivoApi from '~/common/src/api/jivo.api'
import dayjs from 'dayjs'
import { wlHostname } from '~common/utils/wl'
import { useJivoSettingsStore } from '~/common/src/stores/jivoSettings'
import jwtEncode from 'jwt-encode'
import useInfoBlocksStore from '~common/stores/infoBlocks'
import { useEprRegisterStepsStore } from '~/common/src/stores/eprRegisterSteps'
import { useRouter } from 'vue-router'

export default () => {
  const authStore = useAuthStore()
  const servicesVisibleStore = useServicesVisibleStore()
  const uiSettingsStore = useUISettingsStore()
  const jivoSettingsStore = useJivoSettingsStore()
  const infoBlocksStore = useInfoBlocksStore()
  const eprRegisterStepsStore = useEprRegisterStepsStore()

  const domainParam = wlHostname(window.location.hostname)

  const { currentLocale, setLocale } = useI18n()
  const { t } = useI18n()
  const router = useRouter()

  const settingsIsFetched = ref(false)

  const fetchSettings = async () => {
    await Promise.all([
      servicesVisibleStore.fetchPublicServicesVisible(domainParam),
      uiSettingsStore.fetchUISettings(domainParam),
      jivoSettingsStore.fetchJivoSettings(domainParam)
    ])

    settingsIsFetched.value = true
  }

  const headData = computed(() => {
    const title = `${uiSettingsStore.uiSettings?.titleCompanyName} | ${t('app.title')}`

    return { title }
  })

  const initHead = () => {
    useHead(headData)
  }

  const initJivoWidget = async (locale: string) => {
    const { jivoSettings } = jivoSettingsStore

    if (
      !jivoSettings?.is_jivo_enabled ||
      !jivoSettings?.jivo_data?.widget_ids
    ) {
      return
    }

    const widgetId = jivoSettings.jivo_data.widget_ids[locale]

    if (!widgetId) {
      throw new Error('Jivo widget ID is missing')
    }

    jivoApi.initVidget(widgetId)
  }

  function setJivoUser() {
    const secretKey = jivoSettingsStore.jivoSettings?.jivo_data?.secret_key

    const user = authStore.user

    const gaUid = user.ga_uid

    if (!gaUid || !secretKey) return

    const encodedJwt = jwtEncode({ id: gaUid, secret: secretKey }, secretKey)

    jivoApi.setUserToken(encodedJwt)

    jivoApi.updateContactInfo({
      name: user.first_name,
      phone: user.phone_number,
      email: user.email
    })
  }

  const initFavicons = () => {
    const buccketUrl = import.meta.env.PUBLIC_BUCCKET_URL

    const createLink = (fileName: string) => {
      const link = document.createElement('link')

      link.type = 'image/png'
      link.rel = 'shortcut icon'

      document.head.appendChild(link)

      link.href = `${buccketUrl}/wl/${wlHostname(window.location.hostname)}/favicons/${fileName}`
    }

    createLink('favicon-16x16.png')
    createLink('favicon-32x32.png')
  }

  watch(
    [
      settingsIsFetched,
      currentLocale,
      () => uiSettingsStore.uiSettings?.availableLocales
    ],
    async ([settingsIsFetchedVal, localeVal, availableLocalesVal]) => {
      if (!localeVal || !availableLocalesVal?.length) return

      let locale = localeVal

      // сбрасываем на русский, если текущий язык отсутствует в доступных локалях для ВЛ
      if (!availableLocalesVal?.includes(localeVal)) {
        locale = 'ru'
      }

      dayjs.locale(locale)

      await setLocale(locale)

      if (authStore.isAuthenticated) {
        await authStore.setLocale(locale)
      }

      if (settingsIsFetchedVal) {
        initJivoWidget(locale)
      }
    }
  )

  watch(
    [settingsIsFetched, () => authStore.isAuthenticated],
    ([settingsIsFetchedVal, isAuthenticatedVal]) => {
      if (!isAuthenticatedVal) return

      if (authStore.user.locale !== currentLocale.value) {
        setLocale(authStore.user.locale)
      }

      if (settingsIsFetchedVal) {
        setJivoUser()
      }
    },
    { immediate: true }
  )

  watch(
    [
      () => eprRegisterStepsStore.stepsIsNotCompleted,
      () => eprRegisterStepsStore.stepsStateIsFetched
    ],
    ([stepsIsNotCompletedVal, stepsStateIsFetchedVal]) => {
      if (!stepsIsNotCompletedVal || !stepsStateIsFetchedVal) return

      router.push('/register')
    },
    { immediate: true }
  )

  onBeforeMount(async () => {
    initFavicons()

    infoBlocksStore.setDomain(domainParam)

    await fetchSettings()

    initHead()
  })
}
