import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import eprApi from '../api/epr.api'
import type {
  EprRgisterStepName,
  EprRgisterStepStatus,
  IEprRegisterStepsState,
  IEprRgisterStep,
  IEprUnk
} from '../models/Epr'
import { useServicesVisibleStore } from '../stores/servicesVisible'

const initSteps = (): IEprRgisterStep[] => [
  {
    name: 'account_details',
    status: 'pending',
    routes: ['register-base', 'register-confirm']
  },
  { name: 'company_info', status: 'pending', routes: ['register-company'] },
  { name: 'ved_contract', status: 'pending', routes: ['register-contract'] },
  { name: 'user_kyc', status: 'pending', routes: ['register-kyc'] },
  { name: 'data_verification', status: 'pending', routes: ['register-review'] }
]

export const useEprRegisterStepsStore = defineStore(
  'epr-register-steps',
  () => {
    const servicesVisibleStore = useServicesVisibleStore()

    const isLoading = ref(false)
    const steps = ref<IEprRgisterStep[]>(initSteps())
    const stepsState = ref<IEprRegisterStepsState | null>(null)
    const unk = ref<IEprUnk>({})
    const stepsStateIsFetched = ref(false)

    const currentSteps = computed(() => {
      const filteredSteps = steps.value.filter(step => step.name !== 'user_kyc')

      return unk.value.is_bank_client ? filteredSteps : steps.value
    })

    const fetchStepsState = async () => {
      isLoading.value = true

      try {
        const res = await eprApi.fetchRgisterStepsState()

        stepsState.value = res

        stepsStateIsFetched.value = true
      } catch (error) {
        console.error(error)
      } finally {
        isLoading.value = false
      }
    }

    const resetStepsState = () => {
      stepsState.value = null
    }

    const updateStepStatus = ({
      name,
      status
    }: {
      name: EprRgisterStepName
      status: EprRgisterStepStatus
    }) => {
      const idx = steps.value.findIndex(step => step.name === name)

      if (idx === -1) return

      steps.value[idx].status = status
    }

    const updateUnk = (payload: IEprUnk) => {
      unk.value = { ...unk.value, ...payload }
    }

    const stepsIsNotCompleted = computed(
      () =>
        servicesVisibleStore.servicesVisible.is_epr &&
        !stepsState.value?.data_verification
    )

    const fetchUnk = async () => {
      const res = await eprApi.fetchUnk()

      updateUnk(res)
    }

    return {
      stepsState,
      stepsIsNotCompleted,
      isLoading,
      steps,
      currentSteps,
      unk,
      stepsStateIsFetched,
      fetchStepsState,
      updateStepStatus,
      updateUnk,
      fetchUnk,
      resetStepsState
    }
  }
)
