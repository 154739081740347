import countryCodes from 'country-codes-list'
import { defineStore } from 'pinia'
import { computed, reactive } from 'vue'
import type { ISelectorComputedOption } from '../components/AppSelector.vue'

enum CountryProperty {
  countryNameEn = 'countryNameEn',
  countryNameLocal = 'countryNameLocal', // Название страны на родном языке
  countryCode = 'countryCode',
  currencyCode = 'currencyCode',
  currencyNameEn = 'currencyNameEn',
  tinType = 'tinType',
  tinName = 'tinName',
  officialLanguageCode = 'officialLanguageCode',
  officialLanguageNameEn = 'officialLanguageNameEn',
  officialLanguageNameLocal = 'officialLanguageNameLocal',
  countryCallingCode = 'countryCallingCode',
  region = 'region',
  flag = 'flag'
}

export type IRawCode = Record<string, string>

export interface IArrCodeList {
  id: string
  label: string
}

const { customList } = countryCodes

const countryCodesList = defineStore('countryСodes', () => {
  const list = customList(
    CountryProperty.countryCode,
    `{${CountryProperty.countryNameEn}}` // или можно на родном языке ${countryNameLocal}
  )

  const rawCodeList = reactive<IRawCode>(list)

  const arrCodeList = computed<IArrCodeList[]>(() => {
    return Object.keys(rawCodeList).map(key => {
      return {
        id: key,
        label: rawCodeList[key]
      }
    })
  })

  function filterFn(value: string, opt: IArrCodeList[] = arrCodeList.value) {
    const needle = value.toLocaleLowerCase()

    return opt.filter(v => {
      return v.label.toLocaleLowerCase().indexOf(needle) > -1
    })
  }

  function filterFnAppSelector(
    value: string | undefined,
    opt: ISelectorComputedOption<IArrCodeList>[]
  ) {
    if (!value) {
      return opt
    }

    const needle = value.toLocaleLowerCase()

    return opt.filter(v => {
      return v.value.label.toLocaleLowerCase().indexOf(needle) > -1
    })
  }

  function getCountryCodeByName(countryName: string | undefined | null) {
    if (!countryName) {
      return ''
    }

    const countryItem = arrCodeList.value.find(
      items => items.label === countryName
    )

    if (!countryItem) {
      return ''
    }

    return countryItem.id
  }

  return {
    rawCodeList,
    arrCodeList,
    filterFn,
    filterFnAppSelector,
    getCountryCodeByName
  }
})

export default countryCodesList
