import type { IEprRegisterStepsState, IEprUnk } from '../models/Epr'
import { httpClient } from '~common/plugins/httpClient'

export default {
  async fetchRgisterStepsState() {
    const response = await httpClient.get<IEprRegisterStepsState>(
      '/kyc/application/step'
    )

    return response.data
  },

  async fetchUnk() {
    const response = await httpClient.get<IEprUnk>('/onboarding/company/unk')

    return response.data
  }
}
